import { TabPaneProps, Tabs, TabsProps } from 'antd'
import { useReportsDataQuery } from 'features/reports/api/query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import { ActiveTabEnum } from 'types/ICaseInfoPanel'

import SelectReportDropdown from './SelectReportDropdown'

type Props =
  /** Свойства панели вкладок. */
  TabPaneProps &
    /** Свойства вкладок. */
    TabsProps & {
      /** Высота панели. */
      heightPanel: number
      /** Активная вкладка. */
      activeTab: string
      /** Дата создания выбранного отчета. */
      createReportDate: string | undefined
      /** ID выбранного отчета. */
      selectedReportId?: number
      /** Флаг определяющий заключения */
      isReport: boolean
      /** Флаг определяющий заключения */
      isRelated: boolean
      /** ID кейса. */
      caseId: ICase['caseId']
    }

const TabsPanelPreview = ({
  activeTab,
  caseId,
  children,
  createReportDate,
  heightPanel,
  isRelated,
  isReport,
  selectedReportId,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  const { DESCRIPTION, IMAGES, RELATED, REPORT } = ActiveTabEnum
  const { data: reports = [] } = useReportsDataQuery(caseId, 'PLATFORM')
  const tabItems = [
    { key: IMAGES, tab: t('Изображения') },
    { key: DESCRIPTION, tab: t('Описание') },
  ]

  isRelated && tabItems.push({ key: RELATED, tab: t('Связанные случаи') })
  isReport && tabItems.push({ key: REPORT, tab: t(`${t('Заключение')} ${createReportDate}`) })

  return (
    <StyledTabs
      data-testid={`tabs-panel-preview`}
      activeKey={activeTab}
      height={heightPanel}
      hasManyReports={reports.length > 1}
      {...rest}
      tabBarGutter={8}
      size="small"
      tabBarExtraContent={
        reports?.length > 1 && <SelectReportDropdown selectedReportId={selectedReportId} reports={reports} />
      }
    >
      {tabItems.map((tab) => (
        <Tabs.TabPane tab={tab.tab} key={tab.key}>
          {children}
        </Tabs.TabPane>
      ))}
    </StyledTabs>
  )
}

export default TabsPanelPreview

const StyledTabs = styled(Tabs)<{ height: number; hasManyReports: boolean }>`
  width: 100% !important;
  background: var(--color-bg-2);
  height: ${({ height }) => `${height}px`};
  && .ant-tabs {
    width: 100%;
    height: 50px;
  }
  &&& .ant-tabs-tab {
    background: none;
    color: var(--color-text-3);
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    box-sizing: content-box;
    padding: 0 4px 2px 4px;
    border-radius: 2px;
    gap: 10px;
    & :hover {
      color: var(--color-text-1);
    }
  }

  &&& .ant-tabs-tab.ant-tabs-tab-active {
    color: var(--color-text-1);
    background: var(--color-border-1);
    border-radius: 2px;
  }

  && .ant-tabs-nav-operations {
    display: none;
  }
  && .ant-tabs-nav {
    margin: 0;
  }
  &&& .ant-tabs-nav-list {
    justify-content: space-between;
    user-select: none;
    margin: 0;
  }
  && .ant-tabs-nav-wrap {
    background-color: var(--color-bg-3);
    height: 24px;
    flex: ${({ hasManyReports }) => (hasManyReports ? 'none' : 'auto')};
    padding: 2px 3px 3px;
    border-top: 1px solid var(--color-border-1);
  }
  && .ant-tabs-content-holder {
    overflow-y: scroll;
    overflow-x: auto;
  }

  && .ant-tabs-content {
    height: 100%;
  }
  && .ant-tabs-extra-content {
    background-color: var(--color-bg-3);
    flex: 1;
    height: 24px;
    border-top: 1px solid var(--color-border-1);
  }
`
