import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseQuery, useCaseSlideGrouped } from 'features/cases/api/query'
import { useEffect } from 'react'
import { usePrevious } from 'shared/lib/hooks'

export const useSlideGrouped = (caseId: number) => {
  const { refetch } = useCaseQuery({ caseId, source: 'PLATFORM' })
  const { data } = useCaseSlideGrouped(caseId)

  const filesList = useTypedSelector((state) => state.tusFiles.listByCase[caseId]) || []
  const prevFilesList = usePrevious(filesList) || []

  useEffect(() => {
    if (prevFilesList.length - 1 === filesList.length) {
      refetch()
    }
  }, [filesList])

  const slidesIds = data?.flatMap((item) => item.slides.map((slide) => slide.slideId)) || []

  return {
    filesList,
    slideGrouped: data,
    slidesIds,
  }
}
