import 'antd/es/date-picker/style/index'

import { Divider, Dropdown, Menu } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox'
import { useTypedSelector } from 'app/redux/lib/selector'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import icons from 'assets/icons/index'
import { parseISO } from 'date-fns'
import { useLiveQuery } from 'dexie-react-hooks'
import { EAllCasesTabType, ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { DropdownFilterButton } from 'features/cases-management/ui/filters/DropdownFilterButton'
import { FilterBubble } from 'features/cases-management/ui/filters/FilterBubble'
import { caseStagesFilters } from 'features/cases-management/ui/filters/filters'
import { TDict } from 'features/dictionaries'
import { DictionaryNS } from 'features/dictionaries/api/service'
import { NoticesList } from 'features/notifications/NotificationsList'
import { isChatAccess } from 'features/support-chat'
import { SupportChatNew } from 'features/support-chat/ui/SupportChat'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import ContentLayout from 'pages/cases/ui/ContentLayout'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import useFilteredDoctors from 'shared/lib/hooks/useFilteredDoctors'
import i18next from 'shared/lib/i18n/i18n'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { DateRangePicker } from 'shared/ui/DatePicker'
import { AvatarElement, CheckboxElement, IconElement, SearchElement } from 'shared/ui/kit'
import {
  convertDateForQuery,
  createAssignedDoctorsMenu,
  createSubMenu,
  DataFilterQuery,
  DateRange,
  DateType,
  fetchDictionaryData,
  FilterQueryKey,
  FilterType,
  IActiveFilter,
  ICasesTableRequestConfig,
  ISubMenu,
  ISubMenuItem,
  ISubMenuTitleProps,
  menuConfig,
  removeAssignedDoctors,
  updateFilters,
} from 'shared/ui/table/lib/common'
import styled from 'styled-components'
import IListOfItemsCursor from 'types/api/IListOfItemsCursor'
import { IDoctor } from 'types/IUser'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { HALF } from 'viewer/map/layers/objects-counting/lib/helpers/helpers'

import { useCaseManagementRouteParam } from './CasesManagementRoutes'
import CasesTabsContainer from './tabs/CasesTabsContainer'

export const t = i18next.t

type Props = {
  /** Ссылка на кнопку уведомления. */
  notificationButton?: RefObject<HTMLDivElement | null>
}

export const CasesManagementRoute = ({ notificationButton }: Props) => {
  const queryClient = useQueryClient()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isFiltersPicked, setIsFiltersPicked] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState<IActiveFilter[]>([])
  const [lastSelectedDoctorId, setLastSelectedDoctorId] = useState<string>()
  const [searchTerm, setSearchTerm] = useState('')
  const [dataMenuConfig, setDataMenuConfig] = useState<ISubMenu[]>(menuConfig)
  const [dateRangeFilter, setDateRangeFilter] = useState<DateRange | undefined>({
    caseDateFrom: null,
    caseDateTo: null,
  })
  const [subMenuKey, setSubMenuKeyFilter] = useState('')

  const { filterHeight, filterParams, setFilterHeight, setFilterParams } = useCaseManagementContext()
  const { currentTab, menuTab } = useCaseManagementRouteParam()
  const { isCaseRouting } = useSettingsAndUserRoles()
  const currenWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const isChatVisible = isChatAccess(currenWorkspace)
  const filterRef = useRef<HTMLDivElement>(null)
  const wsId = Number(useCurrentWorkspaceId())
  const doctors = queryClient.getQueryData<IListOfItemsCursor<IDoctor>>([QUERY_TYPE.CASE_MANAGEMENT_DOCTORS, wsId])
  const filteredDoctors = useFilteredDoctors({ doctors, filterParams, menuTab, searchTerm })
  const dictionaryDiagnostic = useLiveQuery(() => fetchDictionaryData(DictionaryNS.DIAGNOSTIC_PROCEDURE_TYPE)) as TDict
  const dictionaryMaterialType = useLiveQuery(() => fetchDictionaryData(DictionaryNS.MATERIAL_TYPE)) as TDict
  const { data: diagnosticData } = { ...dictionaryDiagnostic }
  const { data: materialTypeData } = { ...dictionaryMaterialType }
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)

  const diagnosticSubMenu = createSubMenu(
    diagnosticData,
    FilterQueryKey.DIAGNOSTIC_PROCEDURE_TYPE_ID,
    t('Способ получения материала'),
  )
  const materialSubMenu = createSubMenu(materialTypeData, FilterQueryKey.MATERIAL_TYPE_ID, t('Тип материала'))

  useDeepCompareEffect(() => {
    if (menuTab === ECaseTableType.ROUTING && filteredDoctors?.length) {
      setDataMenuConfig((prevConfig) => {
        removeAssignedDoctors(prevConfig)
        const assignedDoctors = createAssignedDoctorsMenu(filteredDoctors, t('Назначенный врач'))
        return [assignedDoctors, ...prevConfig]
      })
    }
  }, [[menuTab], filteredDoctors])

  useEffect(
    () => () => {
      setDataMenuConfig((prevConfig) => {
        removeAssignedDoctors(prevConfig)
        return [...prevConfig]
      })
    },
    [menuTab],
  )

  useEffect(() => {
    if (diagnosticData && !dataMenuConfig.some((item) => item.key === diagnosticSubMenu.key)) {
      dataMenuConfig.push(diagnosticSubMenu)
    }
  }, [diagnosticData, dataMenuConfig])

  useEffect(() => {
    setSelectedFilters(() => [])
  }, [currentTab, menuTab])

  useEffect(() => {
    if (materialTypeData && !dataMenuConfig.some((item) => item.key === materialSubMenu.key)) {
      dataMenuConfig.push(materialSubMenu)
    }
  }, [materialTypeData, dataMenuConfig])

  useEffect(() => {
    setIsFiltersPicked(selectedFilters.length > 0)
    setFilterParams(mapFiltersForRequest(selectedFilters))
    setTimeout(() => setFilterHeight(filterRef.current ? filterRef.current.getBoundingClientRect().height : 0))
  }, [selectedFilters])

  const mapFiltersForRequest = (selectedFilters: IActiveFilter[]): ICasesTableRequestConfig =>
    selectedFilters.reduce<ICasesTableRequestConfig>((acc, filter) => {
      switch (filter.key) {
        case FilterQueryKey.URGENT: {
          // Фильтр срочность может быть либо один, либо никакого.
          if (filter.activeFilters.length === 1) {
            acc[FilterQueryKey.URGENT] = filter.activeFilters[0].queryValue
          }
          break
        }
        case FilterQueryKey.DATE_REGISTRATION: {
          if (filter.activeFilters.length > 0 && filter.activeFilters[0].dateRange) {
            acc[DataFilterQuery.FROM] =
              convertDateForQuery(filter.activeFilters[0].dateRange.caseDateFrom, DateType.FROM) || undefined
            acc[DataFilterQuery.TO] =
              convertDateForQuery(filter.activeFilters[0].dateRange.caseDateTo, DateType.TO) || undefined
          }

          break
        }
        case FilterQueryKey.STAGE: {
          // Фильтр статус, в виде массива строк
          acc[FilterQueryKey.STAGE] = filter.activeFilters.map((activeFilter) => activeFilter.queryValue as string)
          break
        }
        case FilterQueryKey.ASSIGNED_DOCTORS: {
          // Фильтр статус, в виде массива строк
          acc[FilterQueryKey.ASSIGNED_DOCTORS] = filter.activeFilters.map(
            (activeFilter) => activeFilter.queryValue as string,
          )
          break
        }
        case FilterQueryKey.SLIDES_COUNT: {
          acc[FilterQueryKey.SLIDES_COUNT] = filter.activeFilters[0].queryValue
          break
        }
        case FilterQueryKey.MATERIAL_TYPE_ID:
        case FilterQueryKey.DIAGNOSTIC_PROCEDURE_TYPE_ID: {
          acc[filter.key] = filter.activeFilters.map((activeFilter) => activeFilter.queryValue as number)
          break
        }
        default:
          filter.activeFilters.forEach((activeFilter) => {
            acc[filter.key] = activeFilter.queryValue !== undefined ? activeFilter.queryValue : activeFilter.filterValue
          })
      }

      return acc
    }, {})

  const isFilterActive = (itemKey: string) =>
    selectedFilters.some((filter) => filter.activeFilters.some((activeFilter) => activeFilter.key === itemKey))

  const handleDataRangeChange = (dates: [Date | null, Date | null] | null, dateStrings: [string, string]) => {
    const newDateRange = dateStrings[0] ? { caseDateFrom: dateStrings[0], caseDateTo: dateStrings[1] } : undefined

    setDateRangeFilter(newDateRange)

    setSelectedFilters((prevFilters) =>
      updateFilters(
        prevFilters,
        null,
        true,
        {
          filterType: FilterType.DATA_RANGE,
          items: [],
          key: FilterQueryKey.DATE_REGISTRATION,
          title: t('Дата регистрации в ЛИС'),
        },
        newDateRange,
      ),
    )
  }

  const assignedDoctorUserId = filterParams?.assignedDoctorUserId
  useEffect(() => {
    if (assignedDoctorUserId?.length) {
      setLastSelectedDoctorId(String(assignedDoctorUserId[assignedDoctorUserId.length - 1]))
    } else {
      setLastSelectedDoctorId('')
    }
  }, [assignedDoctorUserId])

  const handleFilterChange = (item: ISubMenuItem, subMenu: ISubMenu) => (e: CheckboxChangeEvent) => {
    setSelectedFilters((prevFilters) => updateFilters(prevFilters, item, e.target.checked, subMenu))
  }

  const handleRemoveFilter = useCallback((subMenuKey) => {
    setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter.key !== subMenuKey))

    if (subMenuKey === FilterQueryKey.DATE_REGISTRATION) {
      setDateRangeFilter({ caseDateFrom: null, caseDateTo: null })
    }
  }, [])

  const handleVisibleDropdown = () => {
    setIsDropdownOpen((prev) => !prev)
    setSubMenuKeyFilter('')
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleTitleClick = (subMenuKey: string) => setSubMenuKeyFilter(subMenuKey)

  const menu = (
    <StyledDropdown triggerSubMenuAction="click">
      {dataMenuConfig.map((subMenu) => {
        let menuContent

        if (subMenu.key.startsWith(FilterQueryKey.STAGE)) {
          menuContent = caseStagesFilters({ currentTab, handleFilterChange, isCaseRouting, isFilterActive })
        } else {
          switch (subMenu.filterType) {
            case FilterType.SINGLE_SELECT:
            case FilterType.CHECKBOX:
              menuContent = subMenu.items.map((item, index) => (
                <React.Fragment key={`${item.key || index}-${index}`}>
                  <Menu.Item className="cases-filter-submenu-item">
                    {/** Обёртка для предотвращения закрытия саб меню при клике */}
                    <div onClick={(e) => e.stopPropagation()}>
                      <CheckboxElement
                        style={{ alignItems: 'center' }}
                        onChange={handleFilterChange(item, subMenu)}
                        checked={isFilterActive(item.key)}
                      >
                        {subMenu.key === FilterQueryKey.ASSIGNED_DOCTORS ? (
                          <ContainerSubmenu>
                            <UserAvatar numberValue={index} fullname={item.label} />
                            <LabelContainer>{item.label}</LabelContainer>
                          </ContainerSubmenu>
                        ) : (
                          item.label
                        )}
                      </CheckboxElement>
                    </div>
                  </Menu.Item>
                  {subMenu.key === FilterQueryKey.ASSIGNED_DOCTORS &&
                    String(item.queryValue) === lastSelectedDoctorId && <Divider />}
                </React.Fragment>
              ))
              break

            case FilterType.DATA_RANGE:
              menuContent = (
                <Menu.Item>
                  <div onClick={(e) => e.stopPropagation()} style={{ position: 'relative' }}>
                    <DateRangePicker
                      style={{ left: -480, top: -10 }}
                      onChange={handleDataRangeChange}
                      value={[
                        dateRangeFilter?.caseDateFrom ? parseISO(dateRangeFilter?.caseDateFrom) : null,
                        dateRangeFilter?.caseDateTo ? parseISO(dateRangeFilter?.caseDateTo) : null,
                      ]}
                      open={subMenuKey === 'dateRegistration'}
                      getPopupContainer={(trigger) => {
                        if (trigger?.parentNode && trigger?.parentNode instanceof HTMLElement) {
                          return trigger?.parentNode
                        }
                        return document.body
                      }}
                    />
                  </div>
                </Menu.Item>
              )
              break

            default:
              setSubMenuKeyFilter('')
              menuContent = null
          }
        }

        if (currentTab === EAllCasesTabType.DELETED && subMenu.key.startsWith(FilterQueryKey.STAGE)) {
          return null
        }

        return (
          <Menu.SubMenu
            key={subMenu.key}
            popupClassName={subMenu.filterType === FilterType.DATA_RANGE ? '' : 'submenu-filter-style'}
            title={<SubMenuTitle title={subMenu.title} />}
            onTitleClick={() => handleTitleClick(subMenu.key)}
          >
            {subMenu.key === FilterQueryKey.ASSIGNED_DOCTORS && (
              <>
                <StyledSearchElement
                  value={searchTerm}
                  onChange={handleSearchChange}
                  prefix={null}
                  placeholder={`${t(`Поиск`)}`}
                />
                <Divider />
              </>
            )}

            {menuContent}
          </Menu.SubMenu>
        )
      })}
    </StyledDropdown>
  )

  return (
    <ContentLayout
      rightPanel={
        rightPanelType === SideRightPanelType.NOTIFICATION && <NoticesList notificationButton={notificationButton} />
      }
    >
      <CasesNewRouteWrapper isFiltersPicked={isFiltersPicked} filterHeight={filterHeight}>
        {isCaseRouting && (
          <DropdownWrapper isFiltersPicked={isFiltersPicked} ref={filterRef}>
            <Dropdown
              overlay={menu}
              visible={isDropdownOpen}
              onVisibleChange={handleVisibleDropdown}
              trigger={['click']}
              data-testid={'table-filter-button'}
            >
              <DropdownFilterButton isOpen={isDropdownOpen} isFiltersPicked={isFiltersPicked}>
                <IconElement fill="var(--color-text-1)" name="headerFilter" />
                {isFiltersPicked ? ' ' : t('Фильтр')}
              </DropdownFilterButton>
            </Dropdown>
            {isFiltersPicked &&
              selectedFilters.map((filter) => (
                <FilterBubble
                  key={filter.key}
                  activeFilter={filter}
                  handleRemoveFilter={handleRemoveFilter}
                  handleFilterChange={handleFilterChange}
                  isFilterActive={isFilterActive}
                  dateRangeFilter={dateRangeFilter}
                  handleDataRangeChange={handleDataRangeChange}
                />
              ))}
          </DropdownWrapper>
        )}
        <CasesTabsContainer isFiltersPicked={isFiltersPicked} />
      </CasesNewRouteWrapper>
      {isChatVisible && <SupportChatNew />}
    </ContentLayout>
  )
}

const ContainerSubmenu = styled.div`
  align-items: center;
  display: flex;
  width: 216px;
`

const LabelContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledSearchElement = styled(SearchElement)`
  border-radius: 5px;
  height: 24px;
  width: 94%;
  margin: 8px;
`

const UserAvatar = styled(AvatarElement)`
  vertical-align: middle;
  margin: 0 8px;
  flex-shrink: 0;
`

const CasesNewRouteWrapper = styled.div<{ isFiltersPicked: boolean; filterHeight: number }>`
  height: 100%;
  position: relative;
  .ant-tabs {
    height: ${({ filterHeight, isFiltersPicked }) =>
      isFiltersPicked ? `calc(100% - ${filterHeight / HALF}px)` : '100%'};
  }
`
const DropdownWrapper = styled.div<{ isFiltersPicked: boolean }>`
  background-color: var(--color-bg-1);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: wrap;
  padding-top: ${(props) => (props.isFiltersPicked ? '8px' : '0')};
`
const StyledDropdown = styled(Menu)`
  position: relative;

  width: 236px;
  opacity: 1;

  ${() => modalBackground}
  ${() => modalShadow}

  .ant-dropdown-menu-item {
    align-items: center;
    align-content: center;
  }
  .ant-dropdown-menu-title-content {
    display: flex;
  }

  .ant-dropdown-menu-submenu-expand-icon {
    display: none;
  }
  .ant-dropdown-menu-submenu-title {
    padding-right: 5px;
  }
  .sub-menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .ant-dropdown-menu-submenu-open {
    background-color: var(--color-bg-3) !important;
  }
`

const SubMenuTitle: React.FC<ISubMenuTitleProps> = ({ title }) => (
  /** Отдельный комопнент для заголовка саб меню, чтоб изменить стандартные иконки Antd на кастомные, по дизайну */
  <div className="sub-menu-title">
    <span>{title}</span>
    <icons.sectionIsClose />
  </div>
)
