import { Divider } from 'antd'
import { checkIntegration } from 'entities/lis-integration'
import { FieldSet } from 'entities/lis-integration/types'
import { CaseBiomaterial } from 'features/cases/api/service'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import { TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

type Props = {
  /** Опциональный массив биоматериалов. */
  biomaterials?: CaseBiomaterial[]
  /** Опциональный объект набора полей. */
  fieldSet?: FieldSet
}

const StyledDescription = styled(Descriptions)`
  tbody {
    justify-content: flex-start;
    column-gap: 8px;
    flex-wrap: wrap;
  }
  .ant-descriptions-row {
    flex: none;
  }
`
const StyledDivider = styled(Divider)`
  margin: 8px 0 8px;
`

const Biomaterials = ({ biomaterials, fieldSet }: Props) => {
  const { t } = useTranslation()
  const lisMode = useLisMode()

  return (
    <>
      {checkIntegration('caseInfo', 'biomaterial', lisMode) && (lisMode === 'DZM' ? fieldSet === 'DEFAULT' : true) && (
        <>
          {biomaterials?.map((item) => (
            <div key={item.name} style={{ marginBottom: 8 }}>
              <StyledDescription colon={false} isBreak isFlex style={{ paddingBottom: 2 }}>
                {lisMode === 'gemotest' && (
                  <DescriptionsItem
                    labelStyle={{ display: 'grid', gridTemplateColumns: 'auto 1px' }}
                    contentStyle={{ color: 'var(--color-text-3)' }}
                    label={`${t('Фрагментов')}:`}
                    span={3}
                  >
                    {item.fragmentsCount}
                  </DescriptionsItem>
                )}
                {lisMode === 'DZM' && (
                  <DescriptionsItem
                    labelStyle={{ display: 'grid', gridTemplateColumns: 'auto 1px' }}
                    contentStyle={{ color: 'var(--color-text-3)' }}
                    label={`${t('Флакон')} №`}
                    span={3}
                  >
                    {item.externalId}
                  </DescriptionsItem>
                )}
                <DescriptionsItem
                  labelStyle={{ display: 'grid', gridTemplateColumns: 'auto 1px' }}
                  contentStyle={{ color: 'var(--color-text-3)' }}
                  label={`${t('Блоков')}:`}
                  span={3}
                >
                  {item.blocksCount}
                </DescriptionsItem>
                <DescriptionsItem
                  labelStyle={{ display: 'grid', gridTemplateColumns: 'auto 1px' }}
                  contentStyle={{ color: 'var(--color-text-3)' }}
                  label={`${t('Стекол')}:`}
                  span={3}
                >
                  {item.slidesCount}
                </DescriptionsItem>
              </StyledDescription>
              <TextElement style={{ display: 'block' }} ellipsis>
                {item?.site?.name}
              </TextElement>
            </div>
          ))}
          {!!biomaterials?.length && <StyledDivider />}
        </>
      )}
    </>
  )
}

export default Biomaterials
