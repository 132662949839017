import { TabPaneProps, Tabs, TabsProps } from 'antd'
import { push } from 'connected-react-router'
import { getQueryParams, refetchPages } from 'features/cases-management/lib/helpers'
import {
  EAllCasesTabType,
  ECaseTableType,
  EDistributionTabType,
  EMyCasesTabType,
} from 'features/cases-management/types/ECaseTableType'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { CasesTable, ONE_MINUTE_IN_MS } from 'features/cases-management/ui/cases-table/CasesTable'
import { DoctorsList } from 'features/cases-management/ui/doctor-list/DoctorsList'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import styled from 'styled-components'

/** Пропсы для таба */
type TTabItem = {
  /** Лейбл */
  label: string
  /** Количество кейсов в табе для отображения в название таба */
  count?: number
  /** id таба */
  id: string
  /** Цвет для количества */
  color: string
  /** Тип таба */
  tabType: EDistributionTabType | EMyCasesTabType | EAllCasesTabType
  /** Дополнительная информация о количество кейсов */
  specialCount?: number
}

type TProps = {
  tabsItems: TTabItem[]
  isFiltersPicked?: boolean
} & TabPaneProps &
  TabsProps

export const TableTabs: React.FC<TProps> = ({ isFiltersPicked, tabsItems, ...rest }) => {
  const queryClient = useQueryClient()
  const { currentTab, menuTab } = useCaseManagementRouteParam()
  const dispatch = useDispatch()
  const firstTabType = tabsItems[0].tabType
  const { tabsQueryParams } = useCaseManagementTabContext()
  const { filterParams, setFilterParams, setPanelPreviewVisible, visibleRowsCount } = useCaseManagementContext()
  const wsId = Number(useCurrentWorkspaceId())
  const { isCaseRouting } = useSettingsAndUserRoles()
  const tabType = currentTab ?? firstTabType

  // Запускаем refetch каждые 60 секунд для [Предыдущая, Текущая, Следующая] страниц, если есть
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchPages(
        queryClient,
        menuTab,
        {
          tab: tabType,
          ...getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting),
        },
        filterParams,
        wsId,
        visibleRowsCount,
      )
    }, ONE_MINUTE_IN_MS)

    return () => clearInterval(intervalId)
  }, [menuTab, tabType, tabsQueryParams, filterParams])

  // Очищаем кеш таблиц после дестроя
  useEffect(
    () => () => {
      queryClient.removeQueries({
        predicate: (query) => query.queryKey[0] === QUERY_TYPE.CASE_MANAGEMENT,
      })
    },
    [],
  )

  const onTabClick = (key: EAllCasesTabType | EMyCasesTabType | EDistributionTabType) => {
    if (currentTab !== key) {
      /** Очищаем фильтры */
      setFilterParams({})
      /** Удаляем данные в табе при переходе */
      queryClient.removeQueries({
        predicate: (query) => query.queryKey[0] === QUERY_TYPE.CASE_MANAGEMENT,
      })
      queryClient.invalidateQueries([QUERY_TYPE.CASE_MANAGEMENT_CASES_COUNT, menuTab])
      dispatch(push(`/management/${menuTab}?tab=${key}`))
    }
  }

  useEffect(() => {
    if (tabType !== currentTab) {
      dispatch(push(`/management/${menuTab}?tab=${tabType}`))
    }
  }, [menuTab])

  return (
    <StyledTabs
      {...rest}
      defaultActiveKey={tabType}
      type="card"
      activeKey={tabType}
      tabBarGutter={15}
      size="small"
      onTabClick={(key) => onTabClick(key as EDistributionTabType | EMyCasesTabType | EAllCasesTabType)}
      onChange={() => setPanelPreviewVisible(false)}
    >
      {tabsItems.map(({ color, count, label, specialCount, tabType }) => {
        const customCount = !isCaseRouting && tabType === EMyCasesTabType.ASSIGNED_TO_ME ? specialCount : count
        return (
          <Tabs.TabPane
            key={tabType}
            tab={
              <LabelWrapper data-testid={`${tabType.toLowerCase()}-tab`}>
                <div className="custom-tab-border-left" />
                <p style={{ marginRight: '8px' }}>{label}</p>
                <p style={{ color: color }}>{customCount}</p>
                <div className="custom-tab-border-right" />
              </LabelWrapper>
            }
          >
            <ContentWrapper>
              {menuTab === ECaseTableType.ROUTING && (
                <DoctorsList currentTab={currentTab} isFiltersPicked={isFiltersPicked} />
              )}
              <CasesTable tabType={tabType} />
            </ContentWrapper>
          </Tabs.TabPane>
        )
      })}
    </StyledTabs>
  )
}

const StyledTabs = styled(Tabs)`
  width: 100%;
  height: 100%;

  & .ant-tabs-content {
    height: 100%;
  }

  && .ant-tabs {
    width: 100vw;
  }

  .ant-tabs-nav {
    margin: 0;
  }
  && .ant-tabs-nav-list {
    margin-left: 20px;
  }

  && .ant-tabs-tab {
    background: var(--color-bg-1);
    color: var(--color-text-1);
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 0 6px;
    border-radius: 10px 10px 0px 0px;
    transition-duration: 0s;
  }
  && .ant-tabs-tab.ant-tabs-tab-active {
    position: relative;
    color: var(--color-text-1);
    background: var(--color-bg-2);
    border-radius: 10px 10px 0px 0px;
  }

  .ant-tabs-tab-btn:hover {
    color: var(--color-text-1);
  }

  .ant-tabs-nav-wrap {
    background-color: var(--color-bg-1);
  }

  && .ant-tabs-nav-operations {
    display: none;
  }

  && .ant-tabs-tab.ant-tabs-tab-active .custom-tab-border-left {
    position: absolute;
    width: 10px;
    height: 10px;
    left: -10px;
    bottom: 0px;
    background: var(--color-bg-2);

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 0px;
      bottom: 0px;
      background: var(--color-bg-1);
      border-bottom-right-radius: 10px;
    }
  }
  && .ant-tabs-tab.ant-tabs-tab-active .custom-tab-border-right {
    position: absolute;
    width: 10px;
    height: 10px;
    right: -10px;
    bottom: 0px;
    background: var(--color-bg-2);

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      right: 0px;
      bottom: 0px;
      background: var(--color-bg-1);
      border-bottom-left-radius: 10px;
    }
  }
  .ant-tabs-nav-list {
    margin-top: 16px;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  p {
    margin: 0;
  }
`
const ContentWrapper = styled.div`
  padding: 16px 16px 0;
  display: flex;
  height: 100%;
  flex-direction: row;
`
