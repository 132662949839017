import { useTypedSelector } from 'app/redux/lib/selector'
import { useChangeAnnotationMutation } from 'features/annotations/api/query'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import PlatformAnnotationsContainer from 'features/annotations/ui/platform/PlatformAnnotationsContainer'
import AnnotationsTasksContainer from 'features/annotations/ui/tasks/AnnotationsTasksContainer'
import TasksAnnotationClassSelect from 'features/annotations/ui/TasksAnnotationClassSelect'
import ValidationUsers from 'features/annotations/ui/ValidtionUsers'
import { CaseInfoContainer } from 'features/case-info'
import { CommentsListContainer } from 'features/comments'
import { useNotesQuery } from 'features/notes/api/query'
import { NoticesList } from 'features/notifications/NotificationsList'
import GeoJSON from 'ol/format/GeoJSON'
import { selectTasksViewerUrlTaskId, selectUrlCaseId, viewerPageSlice, ViewerRightPanelType } from 'pages/viewer'
import { useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import { useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { getBooleanOrDefaultAccess } from 'shared/lib/workspaces'
import { CheckboxElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { IAnnotation } from 'types/IAnnotations'
import { IMarkupClass } from 'types/IMarkupSlide'
import { IMarkupTask } from 'types/IMarkupTask'
import ISource from 'types/ISource'
import { SettingsNameEnum } from 'types/IWorkspaces'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector } from 'viewer/container'
import { getSingleFeatureFromGeoJson } from 'viewer/map/lib/utils'

import SidePanelHeaderContainer from './SidePanelHeaderContainer'

const StyledPanel = styled.div`
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-tab {
      width: auto;

      .ant-tabs-tab-btn {
        width: 100%;
      }
    }
  }
`

const TitleWrapper = styled.div`
  padding: 8px 8px 0 16px;
`

const GappedCheckbox = styled(CheckboxElement)`
  padding: 8px 10px 10px 10px;
  margin-left: 0 !important;
`

const CaseInfoWrapper = styled.div`
  min-width: 264px;
  overflow: auto;
  padding: 16px;
`

type Props = {
  urlCaseId: number
  source: ISource
  type: 'ATLAS' | 'DEFAULT' | 'TASK'
  activeViewerSource: ISource
  activeViewerCaseId: number
  activeViewerSlideId: number
  urlSlideId: number
  activeTab: ViewerRightPanelType
  viewerId: TViewerId
}

export enum RightPanelMode {
  COMMENTS = 'Комментарии',
  NOTES = 'Заметки',
}

const ViewerRightPanel = ({
  activeTab,
  activeViewerCaseId,
  activeViewerSlideId,
  activeViewerSource,
  source,
  type,
  urlCaseId,
  urlSlideId,
  viewerId,
}: Props) => {
  const { data: notesData, isLoading: isLoadingNotes } = useNotesQuery(urlCaseId, type === 'ATLAS' || type === 'TASK')
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const caseId = useSelector(selectUrlCaseId) || activeViewerCaseId
  const isCaseNotes =
    notesData &&
    notesData.length > 0 &&
    activeTab === SideRightPanelType.COMMENTS &&
    getBooleanOrDefaultAccess(SettingsNameEnum.LisMode, false, currentWorkspace)
  const dispatch = useDispatch()
  const viewerDispatch = useViewerDispatch(viewerId)
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const queryClient = useQueryClient()
  const { isFastTravel } = useViewerPageProvided()
  const taskData = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const userData = taskData?.participants?.find((item) => item.userId === currentUserId)
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const annotationType = useTypedSelector((state) => state.annotations.annotationType)
  const currTaskClasses = queryClient.getQueryData<IMarkupClass[]>([QUERY_TYPE.TASKS_CLASSES, taskId])
  const hotkeys = currTaskClasses?.filter((item) => item.keyboardShortcut)
  const hotkeysString = hotkeys?.map((item) => `${item.keyboardShortcut}`)?.join() || '`'
  const { mutate: editAnnotation } = useChangeAnnotationMutation({
    caseId: activeViewerCaseId,
    slideId: activeViewerSlideId,
  })
  const { t } = useTranslation()

  const panelTitles = {
    ANNOTATIONS: t('Аннотации'),
    CASE: t('О случае'),
    COMMENTS: t('Комментарии'),
    MENU: t('Меню'),
    NOTES: t('Заметки'),
    NOTIFICATION: '',
    SLIDE: t('О случае'),
  }

  const [hotkeyIsActive, setHotkeyIsActive] = useState<boolean>(false)
  const [annotationsVisible, setAnnotationsVisible] = useState(false)

  const toggleAnnotationsVisible = () => {
    if (annotationsVisible) {
      dispatch(annotationsSlice.actions.setAnnotationsIsVisibleByUser(null))
      dispatch(annotationsSlice.actions.setAnnotationsIsVisibleByClass(null))
    }
    setAnnotationsVisible((prev) => !prev)
  }

  const setCurrClass = (val?: string) => {
    viewerDispatch(annotationsSlice.actions.setCurrentClass(val || null))
  }

  useEffect(() => {
    !!taskId &&
      activeTab !== SideRightPanelType.ANNOTATIONS &&
      dispatch(viewerPageSlice.actions.toggleViewerRightPanel(SideRightPanelType.ANNOTATIONS))
  }, [taskId])

  useEffect(() => {
    if (localStorage.getItem('ORIGIN_CASE_PATH') && activeTab !== SideRightPanelType.CASE) {
      dispatch(viewerPageSlice.actions.toggleViewerRightPanel(SideRightPanelType.CASE))
    }
  }, [])

  const [segmentState, setSegmentState] = useState(RightPanelMode.COMMENTS)

  useEffect(() => {
    if (!(notesData && notesData.length > 0) && !isLoadingNotes) setSegmentState(RightPanelMode.COMMENTS)
  }, [notesData])

  useHotkeys(
    hotkeysString,
    (e) => {
      const currentKey = e.key
      const currentHotkey = hotkeys?.find((item) => item.keyboardShortcut === currentKey)
      setCurrClass(currentHotkey?.name)
      if (annotationType !== currentHotkey?.annotationType && !isFastTravel) {
        setHotkeyIsActive(true)
        dispatch(annotationsSlice.actions.setAnnotationType(currentHotkey?.annotationType))
      } else if (selectedAnnotationsIds.length) {
        const selAnnotations = selectedAnnotationsIds.map((selId) =>
          queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, selId]),
        )
        selAnnotations.forEach((annotation) => {
          if (!annotation) return
          const { data, metric, slideAnnotationId, type } = annotation
          if (type === currentHotkey?.annotationType) {
            const feature = getSingleFeatureFromGeoJson(data?.formattedFeature)
            feature.set('class', currentHotkey?.name)
            editAnnotation({
              data: {
                formattedFeature: new GeoJSON().writeFeature(feature),
                type: 'ANNOTATION',
              },
              metric,
              slideAnnotationId,
              type,
            })
          }
        })
      }
    },
    [hotkeys, isFastTravel],
  )

  return (
    <StyledPanel>
      {activeTab !== SideRightPanelType.CASE && (
        <TitleWrapper id="viewer-right-panel-title">
          <SidePanelHeaderContainer
            side="right"
            activeTab={activeTab}
            title={activeTab ? panelTitles[activeTab] : ''}
            segmentState={segmentState}
            setSegmentState={setSegmentState}
            isCaseNotes={isCaseNotes}
          />
        </TitleWrapper>
      )}
      {activeTab === SideRightPanelType.ANNOTATIONS &&
        (taskId ? (
          <>
            <GappedCheckbox checked={annotationsVisible} onChange={toggleAnnotationsVisible}>
              {t('Показать аннотации в панели')}
            </GappedCheckbox>
            {annotationType && (
              <TasksAnnotationClassSelect
                viewerId={viewerId}
                taskId={taskId}
                hotkeyIsActive={hotkeyIsActive}
                setHotkeyIsActive={setHotkeyIsActive}
                caseId={activeViewerCaseId}
                slideId={activeViewerSlideId}
              />
            )}
            {userData?.access === 'VALIDATE' && (
              <ValidationUsers style={{ height: annotationsVisible ? '250px' : 'auto' }} />
            )}
            {annotationsVisible && (
              <AnnotationsTasksContainer
                viewerId={viewerId}
                caseId={activeViewerCaseId}
                slideId={activeViewerSlideId}
                source={activeViewerSource}
              />
            )}
          </>
        ) : (
          <PlatformAnnotationsContainer viewerId={viewerId} caseId={caseId} slideId={activeViewerSlideId} />
        ))}
      {activeTab === SideRightPanelType.COMMENTS && (
        <CommentsListContainer
          caseId={caseId}
          slideId={urlSlideId}
          viewerId={viewerId}
          source={source}
          segmentState={segmentState}
        />
      )}
      {activeTab === SideRightPanelType.CASE && (
        <CaseInfoWrapper>
          <CaseInfoContainer caseId={caseId} source={activeViewerSource} skipLongData isViewer />
        </CaseInfoWrapper>
      )}
      {activeTab === SideRightPanelType.NOTIFICATION && <NoticesList />}
    </StyledPanel>
  )
}

export default ViewerRightPanel
