import { Space } from 'antd'
import { checkIntegration } from 'entities/lis-integration'
import { getFieldLabelBylisMode } from 'entities/lis-integration/lib/checkIntegration'
import { DictionaryNS } from 'features/dictionaries/api/service'
import { initDictionaries } from 'features/dictionaries/lib/handlers'
import { useReportsDataQuery } from 'features/reports/api/query'
import { reportsSlice } from 'features/reports/model/reportsSlice'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { viewerPageSlice } from 'pages/viewer'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { getLocalizedItem } from 'shared/lib/common'
import { formatDateForDisplay } from 'shared/lib/date'
import { useLisModeAsGemotest } from 'shared/lib/workspaces'
import { Descriptions, DescriptionsItem } from 'shared/ui/description'
import { ButtonElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase, { ICaseRelation } from 'types/ICase'
import { DictionaryItem, ICD10Detailed } from 'types/IDictionary'
import IReport from 'types/IReport'
import ISource from 'types/ISource'

type DescriptionProps = {
  titles: string[]
  subTitle: string
}

const WideButton = styled(ButtonElement)<{ isDisabled: boolean }>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : undefined)};
  width: 100%;
  margin-top: 8px;
`

const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
`

const Description = ({ subTitle, titles }: DescriptionProps) => (
  <StyledDescription>
    <TextElement style={{ color: 'var(--color-text-3)' }}>{subTitle}</TextElement>
    {titles.map((t) => (
      <TextElement key={t}>{t}</TextElement>
    ))}
  </StyledDescription>
)

const Icd10DetailedDescriptions = styled(Descriptions)`
  padding: 5px;
  background: var(--color-bg-3) !important;
  border: 1px solid var(--color-border-1);
  border-radius: 5px;
`

export const Icd10Detailed = ({
  icd10,
  morphologicalIcdO,
  processNature,
  showTnn,
  tnmClassification,
  topographicalIcdO,
}: ICD10Detailed & { showTnn?: boolean }) => {
  const { t } = useTranslation()
  return (
    <Icd10DetailedDescriptions>
      {icd10 && (
        <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Код по МКБ-10')} span={3}>
          {icd10?.code} - {icd10.name}
        </DescriptionsItem>
      )}
      {topographicalIcdO && (
        <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Топографический код по МКБ-О')} span={3}>
          {topographicalIcdO?.code} - {topographicalIcdO?.name}
        </DescriptionsItem>
      )}
      {morphologicalIcdO && (
        <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Морфологический код МКБ-О')} span={3}>
          {morphologicalIcdO?.code} - {morphologicalIcdO?.name}
        </DescriptionsItem>
      )}
      {processNature && (
        <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Характер процесса')} span={3}>
          {processNature?.name}
        </DescriptionsItem>
      )}
      {tnmClassification && showTnn && (
        <DescriptionsItem style={{ paddingBottom: 8 }} label={t('Классификация TNM')} span={3}>
          {tnmClassification}
        </DescriptionsItem>
      )}
    </Icd10DetailedDescriptions>
  )
}

type Props = {
  openModal: () => void
  reportData?: IReport
  readOnly: boolean
  patient: ICase['patient']
  caseId: number
  source: ISource
  skipCaseInfo?: boolean
}

const ReportInfo = ({ caseId, openModal, readOnly, reportData, skipCaseInfo, source }: Props) => {
  const queryClient = useQueryClient()
  const lisMode = useLisMode()
  const { t } = useTranslation()
  const { isLoading, refetch: refetchCaseReports } = useReportsDataQuery(caseId, source)
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const isReadOnly = caseRecord?.permissionLevel === 'READ_ONLY'
  const isRestricted = caseRecord?.relation === ICaseRelation.RESTRICTED
  const isDisabled = isReadOnly || isRestricted
  const isGemotest = useLisModeAsGemotest()
  const workspaceId = caseRecord?.workspace?.workspaceId
  const dispatch = useDispatch()

  const onOpenModal = async () => {
    if (isGemotest) {
      refetchCaseReports().then((res) => {
        const report = res?.data?.at(-1)
        !report ? showNewReportModal() : queryClient.invalidateQueries([QUERY_TYPE.REPORT, { caseId }])
      })
    } else {
      showNewReportModal()
    }
  }

  const showNewReportModal = async () => {
    dispatch(reportsSlice.actions.toggleDictionaryLoading(true))
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(true))
    dispatch(
      reportsSlice.actions.setInitialReport({
        caseMacroDescription: caseRecord?.casePathologicalInfo?.macroDescription,
      }),
    )
    openModal()
    await initDictionaries(workspaceId)
    dispatch(reportsSlice.actions.toggleDictionaryLoading(false))
  }
  if (!reportData) {
    if (readOnly || lisMode === 'DZM' || lisMode === 'DeGenie') {
      return null
    }
    return (
      <WideButton isDisabled={isDisabled} disabled={isDisabled} onClick={onOpenModal}>
        {t('Добавить новое заключение')}
      </WideButton>
    )
  }

  const {
    comment,
    consultedBy,
    createdAt,
    createdBy,
    customInfo,
    gradeGroup,
    icd10,
    icd10DetailedList,
    microDescription,
    morphologyCode,
    report,
  } = reportData

  const icd10Localized = icd10 && getLocalizedItem(icd10 as DictionaryItem, DictionaryNS.ICD_10)
  const morphologyCodeLocalized =
    morphologyCode && getLocalizedItem(morphologyCode as DictionaryItem, DictionaryNS.MORPHOLOGY_CODE)

  const morphDescription = []
  morphologyCodeLocalized && morphDescription.push(morphologyCodeLocalized.name)
  gradeGroup && morphDescription.push(`\nGrade: ${gradeGroup}`)

  return (
    <div>
      <Space size={8} direction="vertical" style={{ width: '100%' }}>
        {checkIntegration('report', 'createdBy-createdAt', lisMode) && createdBy && (
          <Description titles={[formatDateForDisplay(createdAt) || '-']} subTitle={t('Дата заключения')} />
        )}
        {lisMode !== 'DeGenie' && (
          <>
            {checkIntegration('report', 'icd10', lisMode) && icd10Localized && (
              <Description titles={[`${icd10Localized.code} ${[icd10Localized.name]}`]} subTitle={t('МКБ-10')} />
            )}
            {checkIntegration('report', 'morphologyCode', lisMode) && morphologyCodeLocalized && (
              <Description
                titles={[`${morphologyCodeLocalized.code} ${morphDescription}`]}
                subTitle={t('Морфологический код')}
              />
            )}
            {checkIntegration(
              'report',
              'report',
              lisMode,
              lisMode === 'DZM' ? caseRecord?.fieldSet === 'DEFAULT' : true,
            ) &&
              report && <Description titles={[report]} subTitle={t('Заключение')} />}
            {checkIntegration('report', 'microDescription', lisMode) && microDescription && (
              <Description titles={[microDescription]} subTitle={t('Микроописание')} />
            )}
            {checkIntegration(
              'report',
              'comment',
              lisMode,
              lisMode === 'DZM' ? caseRecord?.fieldSet === 'DEFAULT' : true,
            ) &&
              comment && <Description titles={[comment]} subTitle={getFieldLabelBylisMode('comment', lisMode)} />}
            {checkIntegration('report', 'ihcResult', lisMode, caseRecord?.fieldSet === 'IHC') &&
              customInfo?.ihcResult && (
                <Description titles={[customInfo?.ihcResult]} subTitle={getFieldLabelBylisMode('ihcResult', lisMode)} />
              )}
            {checkIntegration('report', 'complexity', lisMode) && customInfo?.complexity && (
              <Description titles={[String(customInfo?.complexity)]} subTitle={t('Категория сложности')} />
            )}
            {checkIntegration('report', 'additionalResearchResult', lisMode) &&
              customInfo?.additionalResearchResult && (
                <Description
                  titles={[customInfo?.additionalResearchResult]}
                  subTitle={t('Результат доп исследований')}
                />
              )}
          </>
        )}

        {checkIntegration('report', 'icd10DetailedList', lisMode) &&
          icd10DetailedList?.map((it, idx) => (
            <Icd10Detailed key={idx} {...it} showTnn={caseRecord?.fieldSet === 'DEFAULT'} />
          ))}

        {checkIntegration('report', 'createdBy-createdAt', lisMode) && createdBy?.userId && (
          <Description titles={[createdBy?.fullname]} subTitle={t('Врач-патологоанатом')} />
        )}
        {checkIntegration('report', 'consultedBy', lisMode) && consultedBy?.userId && (
          <Description titles={[consultedBy?.fullname]} subTitle={t('Консультант')} />
        )}
      </Space>
      {!readOnly && lisMode === 'none' && !reportData?.signed && !skipCaseInfo && (
        <WideButton isDisabled={isDisabled} disabled={isDisabled} loading={isLoading} onClick={onOpenModal}>
          {t('Добавить новое заключение')}
        </WideButton>
      )}
    </div>
  )
}

export default ReportInfo
