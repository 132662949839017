import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { notices } from 'features/notices'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import {
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import {
  CoregistrationDataValue,
  ICoregistrationFeedback,
  sendCoregistrationFeedback,
  SlideKey,
} from 'pages/viewer/lib/coregistration/api/service'
import { useCoregistrationProvided, useViewerCorState } from 'pages/viewer/lib/coregistration/Provider'
import { selectUrlCaseId } from 'pages/viewer/model/viewerPageSlice'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IconButtonElement, IconElement, SpinElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICoregistration from 'types/ICoregistration'
import { ICoregistrationAlgorithm } from 'types/ICoregistrationPayload'
import TViewerId from 'types/TViewerId'

const Actions = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
`
const FloatingButton = styled(IconButtonElement)`
  &.ant-btn-icon-only {
    margin: 0px 2px;
    padding: 4px;
    width: 32px;
    height: 32px;
  }

  &.ant-btn-icon-only:not([disabled]) {
    ${() => modalBackground}
    ${() => modalShadow}
  }

  &.ant-btn-icon-only[disabled] {
    opacity: 0.5;
  }
`

type Props = {
  viewerId: TViewerId
}

/**
 * Компонент ViewerFloatingActions предоставляет плавающие кнопки действий для управления вьювером в режиме сплит-вью.
 *
 * @param {Props} props - Свойства, переданные в компонент.
 * @returns {JSX.Element | null} JSX-элемент с плавающими кнопками действий или null
 */
const ViewerFloatingActions = ({ viewerId }: Props) => {
  /** Стейт видимости тултипа */
  const [tooltipIndex, setTooltipIndex] = useState<number>()
  const { openViewerIds } = useOpenViewers()
  const { slideGroupType } = useViewerIdSlideState(viewerId)
  const { autoCoreg, handableCoreg, removeViewer } = useViewerPageProvided()
  const { getAffineForAllSlides, getReferenceMapBBox, postCorUpdate } = useCoregistrationProvided()
  const state = useViewerCorState(viewerId)
  const { t } = useTranslation()
  const workspaceId = useCurrentWorkspaceId()
  const { unsubscribeFromUser } = useUserStatusContext()
  /** Стейт, указывающий, был ли отправлен фидбек */
  const [alertSent, setAlertSent] = useState(false)
  /** Стейт с координатами референсного Bbox'а в момент отправки запроса на корегистрацию*/
  const [stateBeforeCoregistration, setStateBeforeCoregistration] = useState<ICoregistration['referenceRoi'] | null>(
    null,
  )
  /** Стейт с временем в которое был отправлен запрос на корегистрацию*/
  const [slideCoregistrationDate, setSlideCoregistrationDate] = useState('')
  const caseId = useSelector(selectUrlCaseId)
  const isInitialMount = useRef(true)

  /**
   * Хук эффекта для обновления состояния в момент начала корегистрации.
   *
   * Этот эффект срабаотывает при вызове автоматической корегистрации `autoCoreg`.
   *
   * - Извлекает координаты BBox'а референсного слайда (ROI) с помощью функции `getReferenceMapBBox`.
   * - Сбрасывает состояние отправки фидбека (`alertSent`) на `false`.
   * - Устанавливает дату коррегистрации слайда на текущее время в формате ISO.
   */
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const roi = getReferenceMapBBox()
      roi !== null && setStateBeforeCoregistration(roi)
      setAlertSent(false)
      setSlideCoregistrationDate(new Date().toISOString())
    }
  }, [autoCoreg && state?.slideId])

  if (state === undefined) return null

  const closeViewer = (e: any) => {
    e.stopPropagation()
    removeViewer(viewerId)
    unsubscribeFromUser()
  }

  /**
   * Обработчик для показа тултипа при наведении курсора на кнопку.
   * @param {number} index - Индекс кнопки, для которой нужно показать тултип.
   */
  const handleMouseEnter = (index: number) => setTooltipIndex(index)

  /** Обработчик для скрытия тултипа при уводе курсора от кнопки. */
  const handleMouseLeave = () => setTooltipIndex(undefined)

  /** Обработчик для отправки обратной связи о неверной привязке. */
  const onAlertSend = async () => {
    /** При клике "Обновить привязку" убираем тултип */
    setTooltipIndex(undefined)

    /** @type {ICoregistration['referenceRoi']} - Координаты референсного BBox'a на момент отправки фтдбека */
    const stateOnFeedback = getReferenceMapBBox()

    /** @type {Array} - Аффинные преобразования для всех слайдов */
    const affineData = getAffineForAllSlides()

    /** Создает объект данных коррегистрации, передавая каждому слайду соответствующие ему данные. */
    const coregistrationData: Record<SlideKey, CoregistrationDataValue> = affineData.reduce(
      (accumulator, { affine, slideId, viewerId: localViewerId }) => {
        const key: SlideKey = `pane${localViewerId}Slide` as SlideKey
        accumulator[key] = {
          slideId,
          ...(localViewerId === 'A' ? { stateBeforeCoregistration, stateOnFeedback } : { affine }),
          badCoregistration: localViewerId === viewerId,
        }
        return accumulator
      },
      {} as Record<SlideKey, CoregistrationDataValue>,
    )

    const feedbackData: ICoregistrationFeedback = {
      ...(coregistrationData as Record<SlideKey, CoregistrationDataValue>),
      algorithm: 'CORR' as ICoregistrationAlgorithm,
      slideCoregistrationDate,
    }

    const payload = {
      ...feedbackData,
    }

    try {
      await sendCoregistrationFeedback(payload, caseId, workspaceId)
      notices.openOnCoregistrationFeedbackNotification({
        key: viewerId,
      })
      setAlertSent(true)
    } catch (error) {
      notices.error({
        key: viewerId,
        message: t('Ошибка при отправке обратной связи'),
      })
    }
  }

  /**
   * Обработчик для обновления привязки при клике.
   *
   * @param {MouseEvent<HTMLSpanElement>} e - Событие клика.
   */
  const onCoregUpdate = (e: MouseEvent<HTMLSpanElement>) => {
    /** При клике "Обновить привязку" убираем тултип */
    setTooltipIndex(undefined)
    e.stopPropagation()
    postCorUpdate(viewerId)
  }

  const TooltipIndices = {
    AlertSend: 0,
    CloseViewer: 1,
    CoregUpdate: 2,
  }

  const ButtonConditions = {
    AlertSend:
      openViewerIds.length > 1 &&
      viewerId !== 'A' &&
      slideGroupType === 'MICRO' &&
      autoCoreg &&
      !alertSent &&
      !state.loading,
    CloseViewer: openViewerIds.length > 1,
    CoregUpdate:
      openViewerIds.length > 1 && viewerId !== 'A' && slideGroupType === 'MICRO' && (autoCoreg || handableCoreg),
  }

  const buttons = [
    {
      condition: ButtonConditions.AlertSend,
      disabled: state.loading,
      icon: <IconElement name="alert" />,
      index: TooltipIndices.AlertSend,
      onClick: onAlertSend,
      title: t('Сообщить о неверной привязке'),
    },
    {
      condition: ButtonConditions.CoregUpdate,
      disabled: state.loading,
      icon: state.loading ? <SpinElement /> : <IconElement name="relink" />,
      index: TooltipIndices.CoregUpdate,
      onClick: onCoregUpdate,
      title: t('Обновить привязку'),
    },
    {
      condition: ButtonConditions.CloseViewer,
      disabled: false,
      icon: <IconElement name="cross" />,
      index: TooltipIndices.CloseViewer,
      onClick: closeViewer,
      title: t('Закрыть изображение'),
    },
  ]

  return (
    <Actions id="FLOATING_ACTION">
      {buttons.map((button) =>
        button.condition ? (
          <TooltipElement
            key={button.index}
            title={button.title}
            placement={button.index === TooltipIndices.CloseViewer ? 'bottomRight' : 'bottom'}
            visible={tooltipIndex === button.index}
          >
            <FloatingButton
              onClick={button.onClick}
              onMouseEnter={() => handleMouseEnter(button.index)}
              onMouseLeave={handleMouseLeave}
              shape="circle"
              icon={button.icon}
              disabled={button.disabled}
            />
          </TooltipElement>
        ) : null,
      )}
    </Actions>
  )
}

export default ViewerFloatingActions
