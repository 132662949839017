import { getQueryParams, refetchPages } from 'features/cases-management/lib/helpers'
import { ECaseCursor, ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { IOperationResult, TCaseFavorites } from 'features/cases-management/types/TCaseApi'
import {
  TCaseManagementFilterParams,
  TCaseManagementQueryParams,
} from 'features/cases-management/types/TCasePagination'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { IListOfItemsCursorCases } from 'types/api/IListOfItemsCursor'
import { ICaseDTO } from 'types/ICase'

import caseManagementService, { ICaseAssignPayload } from './service'

export const useCasesTableQuery = (
  tableType: ECaseTableType,
  /** Доступно ли распределние */
  isCaseRouting: boolean,
  queryParams: TCaseManagementQueryParams,
  /** Размер страницы */
  size: number,
  filterParams?: TCaseManagementFilterParams,
  cursor?: string,
  options?:
    | UseInfiniteQueryOptions<
        IListOfItemsCursorCases<ICaseDTO> | undefined,
        unknown,
        ICaseDTO[],
        IListOfItemsCursorCases<ICaseDTO> | undefined,
        QueryKey
      >
    | undefined,
) => {
  const wsId = Number(useCurrentWorkspaceId())
  const casesManagementQuery = useInfiniteQuery<IListOfItemsCursorCases<ICaseDTO> | undefined, unknown, ICaseDTO[]>(
    [QUERY_TYPE.CASE_MANAGEMENT, tableType, JSON.stringify(queryParams), JSON.stringify(filterParams), size, wsId],
    async (data) =>
      await caseManagementService.fetchCasesForTable(
        wsId,
        isCaseRouting,
        tableType,
        queryParams,
        size,
        filterParams,
        !data.pageParam && cursor === ECaseCursor.NULL ? undefined : data.pageParam || cursor,
      ),
    {
      enabled: !!cursor,
      getNextPageParam: (lastPage) => lastPage?.nextPageCursor,
      getPreviousPageParam: (firstPage) => firstPage?.previousPageCursor,
      refetchIntervalInBackground: true,
      refetchOnMount: 'always',
      select: (data): InfiniteData<ICaseDTO[]> => ({
        pageParams: data.pages?.map((list) => {
          if (list) {
            const { content, ...params } = list
            return params
          }
        }),
        pages: data.pages.map((list) => list?.content ?? []),
      }),
      ...options,
    },
  )
  const queryClient = useQueryClient()
  const pages = casesManagementQuery.data?.pages || []
  const casesList = pages.flatMap((page) => page)

  casesList.forEach((caseRecord) => {
    queryClient.setQueryData([QUERY_TYPE.CASE, caseRecord.caseId], () => caseRecord)

    caseRecord.slides.forEach((slide) => {
      queryClient.setQueryData([QUERY_TYPE.SLIDE, slide.slideId], () => ({
        ...slide,
        caseId: caseRecord.caseId,
      }))
    })
  })

  return {
    ...casesManagementQuery,
    casesList,
  }
}

export const useCasesCountQuery = (tableType: ECaseTableType) => {
  const wsId = useCurrentWorkspaceId()

  return useQuery([QUERY_TYPE.CASE_MANAGEMENT_CASES_COUNT, tableType, wsId], () =>
    caseManagementService.fetchCasesCounts(Number(wsId), tableType),
  )
}

/** Хук с запросом списка докторов воркспейса */
export const useCaseManagementDoctorsQuery = (wsId: number) => {
  const { isCaseRouting } = useSettingsAndUserRoles()
  return useQuery([QUERY_TYPE.CASE_MANAGEMENT_DOCTORS, wsId], () =>
    isCaseRouting ? caseManagementService.fetchDoctors(wsId) : undefined,
  )
}
/** Хук для распределения кейсов врачу */
export const useCaseAssignToDoctorMutation = (
  wsId: number,
  options: UseMutationOptions<IOperationResult, Error, unknown, unknown>,
) =>
  useMutation((payload: ICaseAssignPayload) => caseManagementService.assignCasesToDoctor(wsId, payload), {
    onSuccess: options.onSuccess,
  })

const useCasesFavoritesMutation = (actionType: 'add' | 'delete') => {
  const wsId = Number(useCurrentWorkspaceId())
  const { currentTab, menuTab } = useCaseManagementRouteParam()
  const { filterParams, visibleRowsCount } = useCaseManagementContext()
  const { tabsQueryParams } = useCaseManagementTabContext()
  const queryClient = useQueryClient()
  const { isCaseRouting } = useSettingsAndUserRoles()

  return useMutation(
    async (payload: TCaseFavorites) =>
      await caseManagementService.addOrDeleteCasesToFavorites(wsId, payload, actionType),
    {
      onSuccess: () => {
        refetchPages(
          queryClient,
          menuTab,
          {
            tab: currentTab,
            ...getQueryParams(tabsQueryParams, menuTab, currentTab, isCaseRouting),
          },
          filterParams,
          wsId,
          visibleRowsCount,
        )
      },
    },
  )
}

export const useAddCasesToFavoritesMutation = () => useCasesFavoritesMutation('add')
export const useDeleteCasesFromFavoritesMutation = () => useCasesFavoritesMutation('delete')
